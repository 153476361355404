<template>
  <div class="groups">
    <div class="_modal" v-if="choosenDevice" :class="{ show: choosenDevice }">
      <div
        style="height: 100%"
        class="container d-flex flex-column justify-content-center laign-items-center"
      >
        <div class="close center">
          <i @click="choosenDevice = null" class="fa fa-times"></i>
        </div>
        <qr-code
          :id="choosenDevice._id"
          :name="`${choosenDevice.id}-${choosenDevice.category}-${choosenDevice.location}-${choosenDevice.code}.png`"
        />
      </div>
    </div>
    <!---------------------------------------------------->
    <h2 class="text-center" style="color: #0009">{{ "المعدات" | translate }}</h2>
    <hr />
    <div class="filters">
      <div class="row g-3" dir="rtl">
        <div class="col-md-3">
          <label>{{ "نوع المعدة" | translate }}</label>
          <select v-model="filter.category" class="form-control" @change="filterDevices">
            <option :value="null">{{ "كل المعدات" | translate }}</option>
            <option
              v-for="category in categories"
              :key="category._id"
              :value="category.name"
            >
              {{ category.name }}
            </option>
          </select>
        </div>
        <!------------------------>

        <div class="col-md-3">
          <label>{{ "الموقع" | translate }}</label>
          <select v-model="filter.location" class="form-control" @change="filterDevices">
            <option :value="null">{{ "كل المواقع" | translate }}</option>
            <option
              v-for="location in locations"
              :key="location._id"
              :value="location.name"
            >
              {{ location.name }}
            </option>
          </select>
        </div>
        <!--------------------------->
        <div class="col-md-3">
          <label>{{ "كود المعدة" | translate }}</label>
          <input
            type="text"
            class="form-control"
            v-model="filter.code"
            @change="filterDevices"
          />
        </div>
      </div>
      <hr />
    </div>
    <server-table
      title="المعدات"
      api="/filterDevices"
      itemsName="devices"
      :returnedItems="filteredDevices"
      :colsTitles="['نوع الالة', 'الموقع', 'الفئة']"
      :colsNames="['category', 'location', 'code']"
      :colsTypes="['readonly', 'readonly', 'readonly']"
      :loading="loading"
      color="#1f3635"
      :filter="filter"
      :btns="[
        { icon: 'pencil', method: edit },
        { icon: 'trash', method: remove },
        { icon: 'qrcode', method: qrCode },
      ]"
    />
  </div>
</template>

<script>
import axios from "axios";

export default {
  created() {
    // this.getGroups();
  },
  data() {
    return {
      loading: false,
      filteredDevices: [],
      choosenDevice: null,
      filter: {
        category: null,
        location: null,
        code: null,
      },
    };
  },
  methods: {
    async remove(item, items) {
      const confirmed = await confirm("مسح المعدة نهائيا..؟");
      if (!confirmed) return;
      axios.delete("/deleteDevice/" + item._id).then((res) => {
        console.log(res.data);
        if (res.data.err) return this.$store.dispatch("writeMessage", res.data.err);
        const index = items.findIndex((d) => {
          return d._id == item._id;
        });
        items.splice(index, 1);
        this.filteredDevices = items;
      });
    },
    edit(item) {
      this.$router.push("/edit-device/" + item._id);
    },
    preview(id) {
      this.$router.push("/GroupDetails/" + id);
    },
    filterDevices() {
      this.filter = { ...this.filter };
    },
    qrCode(item) {
      this.choosenDevice = null;
      setTimeout(() => (this.choosenDevice = item), 200);
    },
  },
  computed: {
    categories() {
      return this.$store.getters.categories;
    },
    locations() {
      return this.$store.getters.locations;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
}
.groups {
  padding: 20px;
  position: relative;
}
// @import "../../assets/styles/table.scss";
.search {
  margin: 15px;
  right: 0;
  // position: absolute;
  width: 40%;
  @media (max-width: 600px) {
    width: 100%;
  }
  i {
    position: absolute;
    right: 2%;
  }
}
.controls {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  button {
    margin: 2px;
  }
  .input {
    // box-shadow: rgb(71, 71, 71) 1px 1px 1px 1px;
    background-color: rgb(226, 224, 224);
    text-align: center;
    border: 1px #555 solid;
    border-radius: 20px;
  }
}

._modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(10, 10, 10, 0.663);
  transform: translateY(-200vh);
  transition: all 0.2s ease-in-out;
  &.show {
    transform: translateY(0);
  }
  .close {
    i {
      color: #fff;
      font-size: 2rem;
      cursor: pointer;
      margin: 12px;
    }
  }
}
</style>
